.offcanvas {
    width: auto !important;
}

.soda-chitthi-table {
    border: 0;
}

.soda-chitthi-table {
    --bs-table-bg: black !important;
    --bs-table-color: #ffffff !important;
    --bs-table-hover-color: #ffffff !important;
    --bs-table-hover-bg: #ffffff20 !important;
    border-color: #D0AE6B !important;
}

.soda-chitthi-calculations-table {
    border: 0;
}

.soda-chitthi-table td,
.soda-chitthi-table th,
.soda-chitthi-calculations-table td,
.soda-chitthi-calculations-table th {
    vertical-align: middle;
}

.soda-chitthi-table .center,
.soda-chitthi-table th,
.soda-chitthi-calculations-table th,
.soda-chitthi-calculations-table .center {
    text-align: center;
}

.soda-chitthi-table .number,
.soda-chitthi-calculations-table .number {
    text-align: right;
}

.soda-chitthi-table .topLine,
.soda-chitthi-calculations-table .topLine {
    border-top: solid 2px #D0AE6B;
}

.soda-chitthi-table .leftLine,
.soda-chitthi-calculations-table .leftLine {
    border-left: double 4px #D0AE6B;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.printable {
    display: none;
}

@media print {
    body {
        visibility: hidden;
    }

    .printable {
        visibility: visible;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }

    .a4 {
        width: 210mm;
        /* height: 297mm; */
        margin: 2mm;
        padding: 0;
    }

    .non-printable {
        display: none;
    }

    .pagebreak {
        page-break-before: always;
        margin-bottom: 10mm;
    }
}