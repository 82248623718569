.folderTree {
    width: 100%;
    min-height: 56px;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ffffff;
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;
}

.folderTree:hover {
    border: 1px dashed #d0ae6b;
}

.selected {
    border: 1px solid #000000;
    background-color: #d0ae6b;
}