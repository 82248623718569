.offcanvas {
    width: auto !important;
}

.payments-table, .payment-schedule-table {
    border: 1;
}

.payments-table {
    --bs-table-bg: black !important;
    --bs-table-color: #ffffff !important;
    --bs-table-hover-color: #ffffff !important;
    --bs-table-hover-bg: #ffffff20 !important;
    border-color: #D0AE6B !important;
}

.payment-schedule-table tr {
    cursor: pointer;
}

.payments-table td, .payments-table th, .payment-schedule-table td, .payment-schedule-table th {
    vertical-align: middle;
}

.payments-table td {
    text-align: right;
}

.payment-schedule-table td {
    text-align: center;
}

.payment-schedule-table .paid td {
    background-color: #198754 !important;
    cursor: auto !important;
}

.payment-schedule-table .overdue td {
    background-color: #dc3545 !important;
}

.payments-table .center, .payments-table th, .payment-schedule-table .center, .payment-schedule-table th {
    text-align: center;
}

.payments-table .number, .payment-schedule-table .number {
    text-align: right;
}

.payments-table .topLine {
    border-top: solid 2px #D0AE6B;
}

.payments-table .leftLine {
    border-left: double 4px #D0AE6B;
}