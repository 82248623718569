.commonarea-table {
    border: 1px solid #D0AE6B !important;
    --bs-table-bg: black !important;
    --bs-table-color: #ffffff !important;
    --bs-table-hover-color: #ffffff !important;
    --bs-table-hover-bg: #ffffff20 !important;
}

.commonarea-table td,
.commonarea-table th {
    vertical-align: middle;
}

.commonarea-table .center,
.commonarea-table th {
    text-align: center;
}

.commonarea-table .number {
    text-align: right;
}

.commonarea-table .topLine {
    border-top: solid 2px #D0AE6B;
}

.commonarea-table .leftLine {
    border-left: double 4px #D0AE6B;
}
