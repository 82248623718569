html, body, #root {
  height: 100%;
  background-color: rgb(222, 226, 230);
  background-color: #f2f2f1;
  background-color: #D0AE6B;
  background-color: #d8d8d8;
  /* background-image: url(https://transitdevelopers.com/wp-content/uploads/2023/12/banner.png); */
  background-image: url('../public/background.png');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
