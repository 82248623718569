.section-table {
    --bs-table-bg: black !important;
    --bs-table-color: #ffffff !important;
    --bs-table-hover-color: #ffffff !important;
    --bs-table-hover-bg: #ffffff20 !important;
    border-color: #D0AE6B !important;
}

.section-table td,
.section-table th {
    vertical-align: middle;
}

.section-table .center,
.section-table th {
    text-align: center;
}

.section-table .number {
    text-align: right;
}

.section-table .topLine {
    border-top: solid 2px #D0AE6B;
}

.section-table .leftLine {
    border-left: double 4px #D0AE6B;
}
